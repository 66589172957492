import { PERMISSION_PRODUCTS } from '@modules/products/constants';
import { IRouter } from '@routers/interface';
import React from 'react';

export const routerProducts: IRouter = {
  path: '/products/:menuId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'products.name',
  permissionCode: PERMISSION_PRODUCTS.VIEW,
};
