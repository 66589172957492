import React from 'react';
import { IRouter } from '@routers/interface';
import { LogoutOutlined } from '@ant-design/icons';

export const routerLogs: IRouter = {
  path: '/customer/logs',
  loader: React.lazy(() => import('./index')),
  exact: true,
  permissionCode:'CustomerModule.logs',
  name: 'logs.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <LogoutOutlined />,
    activePath: /^customer\/logs$/gi,
  },
};