import React from 'react';
import { IRouter } from '@routers/interface';
import { IdcardOutlined } from '@ant-design/icons';
import { PERMISSION_CUSTOMER } from '@modules/customers/constants';

export const routerCustomer: IRouter = {
  path: '/customer',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <IdcardOutlined />,
    activePath: /^(customer|customer\?+.*)$/gi,
  },
  permissionCode: PERMISSION_CUSTOMER.VIEW,
};
