import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import { IRouter } from '@routers/interface';
import { ArrowLeftImg } from '@assets/images';
import { DownOutlined } from '@ant-design/icons';

interface IMenu {
  data: IRouter;
  activePath?: string;
}

const SubItem: React.FC<IMenu> = (props: IMenu) => {
  const item = props.data;
  let path = item.path;
  if (item.menu?.generatePath) {
    path = item.menu.generatePath(undefined);
  }

  return (
    <NavLink to={path} className="item-sub">
      <FormattedMessage id={item.name} defaultMessage={item.name} />
    </NavLink>
  );
};

const Item: React.FC<IMenu> = (props: IMenu) => {
  const item = props.data;
  const location = useLocation();
  const activePath = item.menu?.activePath;
  const [open, setOpen] = React.useState<any>(
    activePath != null ? location.pathname.match(activePath) : false,
  );

  const active = useMemo(() => {
    if (activePath != null) {
      const pathName = location.pathname;
      const activeMenu = pathName.substring(1).match(activePath);
      return activeMenu ? 'menu-active' : '';
    }
    return matchPath(item.path, location.pathname) ? 'menu-active' : '';
  }, [activePath, item.path, location.pathname]);

  let path = item.path;
  if (item.menu?.generatePath) {
    path = item.menu.generatePath(undefined);
  }
  const subMenu = item?.routes;

  if (subMenu && subMenu.length > 0) {
    return (
      <div className="menu--component--item three-dot">
        {active && <img src={ArrowLeftImg} className="fake-icon" />}
        <div className={`item-label ${active}`} onClick={() => setOpen(!open)}>
          <span>
            {item.menu?.icon && (
              <span className="item-hover__icon">{item.menu.icon}</span>
            )}
            <a className="item__nav">
              <FormattedMessage id={item.name} defaultMessage={item.name} />
            </a>
          </span>
          <span className={`icon-3dot ${!open ? 'active' : ''}`}>
            <DownOutlined />
          </span>
        </div>
        <div className={`dropdown-3dot ${!open ? 'active' : ''}`}>
          {item.routes?.map((child, index) => (
            <SubItem data={child} activePath={location.pathname} key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`menu--component--item ${active}`}>
      {active && <img src={ArrowLeftImg} className="fake-icon" />}
      <Link to={path} className={'item-label'}>
        <span>
          {item.menu?.icon && (
            <span className="item-hover__icon">{item.menu.icon}</span>
          )}
          <span className="item__nav">
            <FormattedMessage id={item.name} defaultMessage={item.name} />
          </span>
        </span>
      </Link>
    </div>
  );
};

export default memo(Item);
