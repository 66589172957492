import React from 'react';
import { IRouter } from '@routers/interface';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { PERMISSION_MENU } from '@modules/menu/constants';

export const routerMenu: IRouter = {
  path: '/menu',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'menu.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <MenuUnfoldOutlined />,
    activePath: /menu|product/gm,
  },
  permissionCode: PERMISSION_MENU.VIEW,
};
