import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerBrands } from '@view/Brands/router';
import { routerLogs } from '@view/Customer/Logs/router';
import { routerCustomer } from '@view/Customer/router';
import { routerDevice } from '@view/Device/router';
import { routerHome } from '@view/Home/router';
import { routerMenu } from '@view/Menu/router';
import { routerPageError } from '@view/PageError/router';
import { routerProducts } from '@view/Products/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerTransactions } from '@view/Transactions/router';
import { routerUsers } from '@view/Users/router';

import { routerDashboard } from '@view/Dashboard/router';
import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerDashboard,
  routerUsers,
  routerCustomer,
  routerTransactions,
  routerDevice,
  routerMenu,
  routerProducts,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerBrands,
  routerViewProfile,
  routerLogs,
  routerPageError,
];

export const publicPage: IRouter[] = [routerLogin, routerPageError];
