export default {
  'users.name': 'Quản lý người dùng',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Tên đầy đủ',
  'users.phone': 'Số điện thoại',
  'users.isActive': 'Trạng thái',
  'users.action': 'Hành động',
  'users.internalComment': 'Ghi chú',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.userType': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.userType.admin': 'Quản trị',
  'users.userType.accountant': 'Kế toán',
  'users.userType.top.up': 'Nạp tiền',
  'transaction.order': 'Mua hàng',
  'users.userType.order': 'Bán hàng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'user.menuId': 'Thực đơn',
  'menu.brand': 'Nhãn hàng',
};
