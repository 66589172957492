import React from 'react';
import { IRouter } from '@routers/interface';
import { BoldOutlined } from '@ant-design/icons';
import { PERMISSION_BRANDS } from '@modules/brands/constants';

export const routerBrands: IRouter = {
  path: '/brands',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'brands.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <BoldOutlined />,
  },
  permissionCode: PERMISSION_BRANDS.VIEW,
};
