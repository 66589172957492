import { Modal } from 'antd';

const { confirm } = Modal;
interface IFaceConfirm {
  handleOk?: (...args: any[]) => any;
  handleCancel?: (...args: any[]) => any;
  content?: any;
  title?: any;
  style?: React.CSSProperties;
  okText?: string;
  width?: any;
  cancelText?: string;
  type?: 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm';
  icon?: React.ReactNode;
  afterClose?: () => void;
}

export const DeleteConfirm = (props: IFaceConfirm) => {
  const {
    handleOk,
    handleCancel,
    content,
    title,
    okText,
    width,
    cancelText,
    type,
    style,
    icon,
    afterClose,
  } = props;
  return confirm({
    style: style || { marginTop: '5%' },
    centered: true,
    icon,
    width: width || 500,
    maskClosable: true,
    title,
    content,
    type: type || 'confirm',
    cancelText,
    okText,
    className: 'modal-delete-confirm',
    onCancel: handleCancel,
    afterClose: afterClose,
    cancelButtonProps: {
      style: {
        display: handleCancel ? 'block' : 'none',
      },
    },
    onOk: handleOk,
  });
};
