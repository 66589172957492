export default {
  'roles.name': 'Role management',
  'roles.roleName': 'Role name',
  'roles.rolePermissions': 'Permissions',
  'roles.permissionCodes': 'Permissions',
  'roles.createdAt': 'Created at',
  'roles.action': 'Action',
  'Per.Users.Create': 'Add user',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'roles.create': 'Create role',
  'roles.information': 'Role information',
  'roles.update': 'Edit role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update role successfully',
  'Mes.Roles.Add.Successfully': 'Create role successfully',

  'roles.createdBy': 'Made by',
  'roles.createDateTime': 'Date created',
  'roles.internalComment': 'Note',
  'roles.isActive': 'Operating status',
  'roles.delete': 'Delete the role',
  'roles.content.delete': 'Are you sure you want to erase this role?',
  'roles.add.name': 'Add a new role',
  'roles.info.name': 'Role information',
  'roles.edit.name': 'Update the role',

  'role.RoleModule': 'Role',
  'role.UsersModule': 'User',
  'role.DeviceModule': 'Device',
  'role.CustomerModule': 'Customer',
  'role.TransactionsModule': 'Transaction',
  'role.ProductsModule': 'Product',
  'role.MenuModule': 'Menu',
  'role.BrandsModule': 'Brand',
  'role.TicketsModule': 'Ticket',
};
