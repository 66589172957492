export default {
  'brands.name': 'Quản lý nhãn hàng',
  'brands.brandName': 'Tên nhãn hàng',
  'brands.brandAddress': 'Địa chỉ nhãn hàng',
  'brands.createdBy': 'Người tạo',
  'brands.createDateTime': 'Ngày tạo',
  'brands.internalComment': 'Ghi chú',
  'brands.isActive': 'Trạng thái hoạt động',
  'brands.action': 'Thao tác',
  'brands.create': 'Thêm nhãn hàng',
  'brands.update': 'Cập nhật nhãn hàng',
  'brands.information': 'Thông tin nhãn hàng',
  'brands.delete': 'Xóa nhãn hàng',
  'brands.content.delete': 'Bạn có chắc chắn muốn xóa nhãn hàng này không?',
  'dashboard.history.time': 'Thời gian',
  'report.title': 'Báo cáo',
  'dashboard.name': 'Dashboard',
};
