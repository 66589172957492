import React from 'react';
import { IRouter } from '@routers/interface';
import { SecurityScanOutlined } from '@ant-design/icons';
import { PERMISSION_ROLES } from '@modules/roles/constants';

export const routerRoles: IRouter = {
  path: '/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'roles.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <SecurityScanOutlined />,
    activePath: /roles/gi,
  },
  permissionCode: PERMISSION_ROLES.VIEW,
};

export const routerRolesAdd: IRouter = {
  path: '/roles/add',
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  // permissionCode: 'RolesCreate',
  name: 'roles.add.name', //translate here for breadcrumb and sidebar
};

export const routerRolesEdit: IRouter = {
  path: '/roles/edit/:id',
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  // permissionCode: 'RolesUpdate',
  name: 'roles.edit.name', //translate here for breadcrumb and sidebar
};

export const routerRolesInfo: IRouter = {
  path: '/roles/info/:id',
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  // permissionCode: 'RolesView',
  name: 'roles.info.name', //translate here for breadcrumb and sidebar
};
