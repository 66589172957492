export interface ISummary {
  totalCoin: number;
  totalCoinActive: number;
  totalCoinInactive: number;
  amountTransactionActive: number;
  amountTransactionInactive: number;
}

export const PERMISSION_TRANSACTIONS = {
  CREATE: 'TransactionsModule.create',
  VIEW: 'TransactionsModule.view',
  UPDATE: 'TransactionsModule.update',
  DELETE: 'TransactionsModule.delete',
};
