import '@shared/assets/css/animation.css';
import '@styles/styles.scss';

import lodash from 'lodash';
import React, { memo, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import locale from '@locale/index';
import { TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';
import PublicPage from '@routers/component/PublicPage';
import ThemeContext from '@shared/hook/ThemeContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(duration);

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});

const App: React.FC = () => {
  const { token } = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  const navigate = useNavigate();

  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const initStyle = React.useMemo(() => {
    return {
      colorPrimary: '#fc693a',
      bgPrimary: 'linear-gradient(124deg, #FFD740 0%, #FC4036 100%)',

      colorPrimaryLight: '#fc693a',
      colorText: '#4f4c4d',
      colorTextSecondary: '#4f4c4d',
      colorLink: '#000',
      colorBgContainer: '#fff',
      colorBgLayout: '#f5f5f5',
      fontFamily: 'Roboto',
      colorError: 'red',
      colorTextBase: '#000',
      colorTextLightSolid: '#fff',
    };
  }, []);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ThemeContext token={initStyle}>
        <DndProvider backend={HTML5Backend}>
          <MainView statusLogin={!lodash.isEmpty(token)} />
        </DndProvider>
      </ThemeContext>
    </IntlProvider>
  );
};

export default App;
