export default {
  'device.name': 'Quản lý thiết bị',
  'device.deviceName': 'Tên thiết bị',
  'device.deviceCode': 'Mã thiết bị',
  'device.deviceCapacity': 'Công suất thiết bị',
  'device.deviceLong': 'Kinh độ',
  'device.deviceLat': 'Vĩ độ',
  'device.isActive': 'Trạng thái hoạt động',
  'device.devicePassword': 'Mật khẩu',
  'device.create': 'Thêm thiết bị',
  'device.update': 'Cập nhật thiết bị',
  'device.information': 'Thông tin thiết bị',
  'device.delete.title': 'Xóa thiết bị',
  'device.content.delete': 'Bạn có chắc xóa thiết bị. Thiết bị bị xóa sẽ không thể không phục',
  'device.deviceType': 'Kiểu thiết bị',
  'device.delete': 'Xóa thiết bị',
  'device.action': 'Thao tác',
  'device.brandId': 'Nhãn hàng',
  'devices.type.checkin':'Checking',
  'devices.type.register':'Đăng ký',
  'device.ticketRegex':'Loại vé',
  'device.deviceMode':'Lọai thiết bị',
  'devices.mode.qr':'Came biến QR',
  'devices.mode.nfc':'Cảm biến NFC',
  'devices.mode.all':'Cảm biến HID',
};
