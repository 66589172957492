import React from 'react';
import { IRouter } from '@routers/interface';
import { TransactionOutlined } from '@ant-design/icons';
import { PERMISSION_TRANSACTIONS } from '@modules/transactions/constants';

export const routerTransactions: IRouter = {
  path: '/transactions',
  loader: React.lazy(() => import('./index')),
  exact: true,

  name: 'transactions.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <TransactionOutlined />,
  },
  permissionCode: PERMISSION_TRANSACTIONS.VIEW,
};
